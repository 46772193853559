import React from 'react';
import Navbar from '../components/Navbar';
import Hero from './Hero';
import About from './About';
import Work from './Work';
import Contact from './Contact';
import Footer from './Footer';
import { useSelector } from 'react-redux';
import ParticlesComponent from '../components/ParticlesComponent';


export default function Home() {
    const {portfolioData} = useSelector((state) => state.root);

    return (
        <>
        {portfolioData && 
            <div className='font-roboto-mono'>
                <ParticlesComponent />
                <Navbar />
                <Hero />
                <About />
                {portfolioData.projects && <Work />}
                <Contact />
                <Footer />
            </div>
        }
        </>
        
    );
}