import { Form } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShowLoading, HideLoading } from "../../redux/rootSlice";
import axios from "axios";
import { message } from "antd";

function AdminHero() {
  const dispatch = useDispatch();
  const { portfolioData } = useSelector((state) => state.root);
  const onFinish = async (values) => {
    try {
      dispatch(ShowLoading());
      const response = await axios.post("/api/portfolio/update-intro", {
        ...values,
        _id: portfolioData.intro._id,
      });
      dispatch(HideLoading());
      if (response.data.success) {
        message.success(response.data.message);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  return (
    <Form
      onFinish={onFinish}
      layout="vertical"
      initialValues={portfolioData.intro}
    >
      <Form.Item name="welcomeText" label="Welcome Text">
        <input placeholder="Welcome Text" />
      </Form.Item>
      <Form.Item name="firstName" label="First Name">
        <input placeholder="First Name" />
      </Form.Item>
      <Form.Item name="lastName" label="Last Name">
        <input placeholder="Last Name" />
      </Form.Item>
      <Form.Item name="tagline" label="Tagline">
        <input placeholder="Tagline" />
      </Form.Item>
      <Form.Item name="description" label="Description">
        <textarea placeholder="description" />
      </Form.Item>
      <div className="flex justify-end">
        <button
          className="px-5 py-2 bg-gold border-2 border-blue text-blue uppercase"
          type="submit"
        >
          save
        </button>
      </div>
    </Form>
  );
}

export default AdminHero;
