import React, { useEffect } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import { Tabs } from "antd";
import AdminHero from "./AdminHero";
import AdminAbout from "./AdminAbout";
import { useSelector } from "react-redux";
import AdminWork from "./AdminWork";

export default function AdminHome() {
  const { portfolioData } = useSelector((state) => state.root);

  useEffect(() => {
    if(!localStorage.getItem("token")) {
      window.location.href = "/admin-login";
    }
  }, [])
  
  return (
    portfolioData && (
      <div>
        <AdminNavbar />
        <div className="flex gap-10 items-center px-5 pt-5 justify-between">
          <div className="flex gap-10 items-center">
            <h1 className="text-3xl sm:text-xl text-blue">Portfolio Admin</h1>
            <div className="h-[2px] w-60 bg-blue"></div>
          </div>
          <h1 className="underline text-xl cursor-pointer text-blue" onClick={() => {
            localStorage.removeItem("token");
            window.location.href = "/admin-login";
          }}>Logout</h1>
        </div>

        <Tabs
          className="px-5"
          defaultActiveKey="1"
          items={[
            {
              label: "Intro",
              key: "1",
              children: <AdminHero />,
            },
            {
              label: "About Me",
              key: "2",
              children: <AdminAbout />,
            },
            {
              label: "Work",
              key: "3",
              children: <AdminWork />,
            },
          ]}
        />
      </div>
    )
  );
}
