import React from 'react'

function Pagebutton({title, link}) {
  const isExternalLink = (url) => {
    try {
      const linkUrl = new URL(url);
      return linkUrl.origin !== window.location.origin;
    } catch (e) {
      // If URL constructor throws, it's not a valid URL, consider it internal.
      return false;
    }
  };

  const handleClick = () => {
    if (isExternalLink(link)) {
      window.open(link, '_blank');
    } else {
      // Handle internal navigation, e.g., using React Router's history.push
      // For example, you could use:
      // history.push(link);
      // Ensure you have access to `history` from React Router
      window.location.href = link; // Fallback to regular navigation for internal links
    }
  };

  return (
    <button onClick={handleClick} className="relative border border-gold rounded-md px-10 py-3 text-lg text-gold bg-transparent cursor-pointer overflow-hidden transition-colors duration-300 group">
      <span className="relative z-10 bg-transparent font-roboto-mono uppercase transition-colors duration-300 group-hover:text-blue">{title}</span>
      <span className="absolute inset-0 w-full h-full bg-gold transform scale-y-0 group-hover:scale-y-100 transition-transform origin-bottom duration-300 z-0"></span>
    </button>
)
}

export default Pagebutton