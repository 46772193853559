import React from 'react';

export default function SectionTitle({title}) {
  return (
    <div className='flex gap-10 items-center py-5'>
        <h1 className='text-3xl text-gold uppercase z-10'>{title}</h1>
        <div className='w-60 h-[1px] bg-gold z-10'></div>
    </div>
  );
}
