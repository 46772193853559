import React from 'react'
import SocialLinks from '../components/SocialLinks'

export default function Footer() {
  return (
    <div className='pb-10 bg-blue/80 relative z-5'>
        <div className='h-[1px] w-full bg-gold'></div>
        <div className='flex items-center justify-center flex-col mt-10 opacity-80'>
            <h1 className='text-white'>Designed and Developed by</h1>
            <h1 className='text-white'>Parikshit Sood</h1>
        </div>
        <SocialLinks />
    </div>
  )
}