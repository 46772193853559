import React, { useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import "react-toastify/dist/ReactToastify.css";
import SectionTitle from "../components/SectionTitle";

export default function Contact() {
  const form = useRef();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    emailjs
      .sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, {
        publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      })
      .then(
        () => {
          toast.success(
            "Message received! I will get back to you as soon as possible.",
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
            }
          );
        },
        (error) => {
          toast.error(
            "There was an error sending your message.",
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
            }
          );
        }
      );
  };

  return (
    <div id="contact" className="px-40 py-20 sm:px-7 bg-transparent gap-6 relative z-10">
      <SectionTitle title="Contact" />
      <form ref={form} onSubmit={handleSubmit} className="space-y-4 py-8">
        <div className="flex flex-row gap-4 sm:flex-col">
          <input
            type="text"
            id="first_name"
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            placeholder="First Name*"
            className="w-1/2 p-2 border border-gray-300 rounded-md sm:w-full sm:text-sm text-md"
            required
          />
          <input
            type="text"
            id="last_name"
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            placeholder="Last Name*"
            className="w-1/2 p-2 border border-gray-300 rounded-md sm:w-full sm:text-sm text-md"
            required
          />
        </div>
        <div className="flex flex-row gap-4 sm:flex-col">
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email*"
            className="w-1/2 p-2 border border-gray-300 rounded-md sm:w-full sm:text-sm text-md"
            required
          />
          <input
            type="phone"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Phone"
            className="w-1/2 p-2 border border-gray-300 rounded-md sm:w-full sm:text-sm text-md"
          />
        </div>
        <div>
          <input
            type="text"
            id="subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            placeholder="Subject*"
            className="w-full p-2 border border-gray-300 rounded-md sm:w-full sm:text-sm text-md"
            required
          />
        </div>
        <div>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Message*"
            className="w-full p-2 border border-gray-300 rounded-md h-32 sm:w-full sm:text-sm text-md"
            required
          ></textarea>
        </div>
        <button
          type="submit"
          className="px-4 py-2 bg-gold text-black rounded-md hover:text-blue hover:bg-white sm:text-sm text-md duration-300"
        >
          SUBMIT
        </button>
      </form>
      <ToastContainer newestOnTop={false} rtl={false} className="relative z-10" />
    </div>
  );
}
