import React from "react";
import SectionTitle from "../components/SectionTitle";
import { useSelector } from "react-redux";
import Face from "../images/face.png";

export default function About() {
  const { portfolioData } = useSelector((state) => state.root);
  const { about } = portfolioData;
  const { skills, lottieURL, description1, description2 } = about;

  return (
    <div id="about" className="px-40 py-20 sm:px-7 bg-transparent gap-6 relative z-10">
      <SectionTitle title="About Me" className="py-2" />

      <div className="flex w-full items-center gap-12 py-8 sm:flex-col">
        <div className="w-1/3 sm:w-1/2">
          <img src={Face} />
        </div>
        <div className="flex flex-col gap-5 w-2/3 sm:w-full">
          <p className="text-white text-justify leading-6">{description1}</p>
          <p className="text-white text-justify leading-6">{description2}</p>
        </div>
      </div>
      <div className="py-8">
        <h1 className="text-gold text-xl">
          Some technologies I have recently used:
        </h1>
        <div className="flex flex-wrap gap-10 mt-5">
          {skills.map((skill, index) => (
            <div
              className="flex items-center justify-center py-3 px-5 rounded-md border border-gold"
              key={index}
            >
              <h1 className="text-gold">{skill}</h1>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
