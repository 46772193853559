import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { ReactComponent as Logo } from "../images/logo-blue.svg";
import { ReactComponent as Menu } from "../images/burger-menu.svg";
import { ReactComponent as CloseMenu } from "../images/delete.svg";
import Sitenav from "./Sitenav";
import MobileNav from "./MobileNav";

export default function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.getElementById("hero");
      if (heroSection) {
        const heroTop = heroSection.getBoundingClientRect().top;
        setIsScrolled(heroTop < 0);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`w-full flex items-center justify-between sticky top-0 z-30 drop-shadow-lg transition-all duration-500 ${
        isScrolled || isMobileMenuOpen ? "bg-white" : "bg-gold"
      } sm:h-[10vh]`}
    >
      <Link
        to="hero"
        spy={true}
        smooth={true}
        offset={0}
        duration={500}
      >
        <Logo className="w-9 h-9 m-6 fill-current text-blue cursor-pointer" />
      </Link>
      <Sitenav />
      <div className="ml-auto m-6 flex space-x-4 lg:hidden">
        <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
          {isMobileMenuOpen ? (
            <CloseMenu className="w-8" />
          ) : (
            <Menu className="w-8" />
          )}
        </button>
      </div>
      {isMobileMenuOpen && (
        <div className="absolute top-full left-0 w-full bg-white lg:hidden">
          <MobileNav />
        </div>
      )}
    </nav>
  );
}
