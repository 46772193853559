import React from 'react';
import { ReactComponent as Logo } from '../images/logo-blue.svg';

export default function AdminNavbar() {
    return (
        <div className={`w-full flex items-center justify-between sticky top-0 z-20 drop-shadow-lg transition-all duration-500 bg-gold sm:h-[10vh]`}>
            <Logo className='w-9 h-9 m-6 fill-current text-white' />
        </div>
    );
}
