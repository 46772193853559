import React from 'react';

export default function Button({title}) {
    return (
        <button className="relative rounded-md ml-3 py-1 px-3 text-lg text-blue bg-transparent cursor-pointer overflow-hidden transition-colors duration-300 group">
            <span className="relative z-10 bg-transparent font-roboto-mono uppercase transition-colors duration-300 group-hover:text-white">{title}</span>
            <span className="absolute inset-0 w-full h-full bg-gradient-to-r from-gold to-blue transform scale-y-0 group-hover:scale-y-100 transition-transform origin-bottom duration-300 z-0"></span>
        </button>
    );
}
