import React from 'react';
import Navbutton from './Navbutton';
import { Link } from 'react-scroll';

export default function MobileNav() {
    return (
        <div className='lg:hidden flex flex-col py-8 gap-4'>
            <Link to='about' spy={true} smooth={true} offset={0} duration={500}>
                <Navbutton title='about' />
            </Link>
            <Link to='work' spy={true} smooth={true} offset={0} duration={500}>
                <Navbutton title='work' />
            </Link>
            <Link to='contact' spy={true} smooth={true} offset={0} duration={500}>
                <Navbutton title='contact' />
            </Link>
        </div>
    );
}
