import { Form } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShowLoading, HideLoading } from "../../redux/rootSlice";
import axios from "axios";
import { message } from "antd";

export default function AdminAbout() {
  const dispatch = useDispatch();
  const { portfolioData } = useSelector((state) => state.root);
  const onFinish = async (values) => {
    try {
      const tempSkills = values.skills.split(", ");
      values.skills = tempSkills;
      dispatch(ShowLoading());
      const response = await axios.post("/api/portfolio/update-about", {
        ...values,
        _id: portfolioData.about._id,
      });
      dispatch(HideLoading());
      if (response.data.success) {
        message.success(response.data.message);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  return (
    <Form
      onFinish={onFinish}
      layout="vertical"
      initialValues={{
        ...portfolioData.about,
        skills: portfolioData.about.skills.join(", "),
      }}
    >
      <Form.Item name="description1" label="Description #1">
        <textarea placeholder="Description #1" />
      </Form.Item>
      <Form.Item name="description2" label="Description #2">
        <textarea placeholder="Description #2" />
      </Form.Item>
      <Form.Item name="skills" label="Skills">
        <textarea placeholder="Skills" />
      </Form.Item>
      <div className="flex justify-end">
        <button
          className="px-5 py-2 bg-gold border-2 border-blue text-blue uppercase"
          type="submit"
        >
          save
        </button>
      </div>
    </Form>
  );
}
