import React from "react";
import { Link } from "react-scroll";
import Navbutton from "./Navbutton";

export default function Sitenav() {
  return (
    <div className="items-center ml-auto mr-10 space-x-4 flex sm:hidden">
      <Link to="about" spy={true} smooth={true} offset={0} duration={500}>
        <Navbutton title="about" />
      </Link>
      <Link to="work" spy={true} smooth={true} offset={0} duration={500}>
        <Navbutton title="work" />
      </Link>
      <Link to="contact" spy={true} smooth={true} offset={0} duration={500}>
        <Navbutton title="contact" />
      </Link>
    </div>
  );
}
