import React from "react";
import { ReactComponent as Logo } from "../images/logo-gold.svg";

const Loader = () => {
  return (
    <div className="fixed inset-0 z-[10000]">
      <div className="flex items-center justify-center h-screen bg-blue">
        <Logo className="animate-spin h-24 w-24" />
      </div>
    </div>

  );
};

export default Loader;
