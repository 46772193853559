import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-scroll";

export default function Hero() {
  const { portfolioData } = useSelector((state) => state.root);
  const { intro } = portfolioData;
  const { welcomeText, firstName, lastName, tagline, description } = intro;

  return (
    <div
      id="hero"
      className="h-[90vh] bg-transparent flex flex-col items-start px-40 sm:px-7 gap-8 justify-center py-10 relative z-10"
    >
      <h1 className="text-white text-xl">{welcomeText}</h1>
      <h1 className="text-7xl sm:text-3xl font-semibold text-gold">
        {firstName} {lastName}
      </h1>
      <h1 className="text-6xl sm:text-2xl text-white font-semibold">
        {tagline}
      </h1>
      <p className="text-white text-xl">{description}</p>
      <Link to="contact" spy={true} smooth={true} offset={0} duration={500}>
        <button className="relative border border-gold rounded-md px-10 py-3 text-lg text-gold bg-transparent cursor-pointer overflow-hidden transition-colors duration-300 group">
            <span className="relative z-10 bg-transparent font-roboto-mono uppercase transition-colors duration-300 group-hover:text-blue">GET IN TOUCH</span>
            <span className="absolute inset-0 w-full h-full bg-gold transform scale-y-0 group-hover:scale-y-100 transition-transform origin-bottom duration-300 z-0"></span>
        </button>
      </Link>
    </div>
  );
}
