import React from "react";
import SectionTitle from "../components/SectionTitle";
import PageButton from "../components/Pagebutton";
import { useSelector } from "react-redux";

export default function Work() {
  const [selected, setSelected] = React.useState(0);
  const {portfolioData} = useSelector((state) => state.root);
  const {projects} = portfolioData;


  return (
    <div id="work" className="px-40 py-20 sm:px-7 bg-transparent gap-6 relative z-10">
      <SectionTitle title="Work" />
      <div className="flex py-8 gap-20 sm:flex-col">
        <div className="flex flex-col border-l border-gold w-1/4 sm:flex-row sm:w-full sm:overflow-x-auto custom-scrollbar">
          {projects.map((project, index) => (
            <div
              onClick={() => {
                setSelected(index);
              }}
              className="cursor-pointer"
              key={index}
            >
              <h1
                className={`px-5 text-xl py-3 ${
                  selected === index
                    ? "text-gold border-gold border-l-2 -ml-[3px] sm:ml-0 border-b-2 sm:w-full"
                    : "text-white"
                }`}
              >
                {project.name}
              </h1>
            </div>
          ))}
        </div>
        <div className="flex flex-col gap-5 w-full">
          <h1 className="text-xl text-gold font-bold">
            {projects[selected].name}
          </h1>
          <h1 className="text-xl text-white">{projects[selected].period}</h1>
          <div className="flex flex-wrap gap-2 my-2">
            <h1 className="text-gold py">Built using </h1>
            {projects[selected].skills.map((skill, index) => (
              <div className="py" key={index}>
                <h1 className="text-gold">
                  {skill}
                  {index !== projects[selected].skills.length - 1
                    ? ","
                    : null}{" "}
                </h1>
              </div>
            ))}
          </div>
          <p className="text-white text-md text-justify leading-6">
            {projects[selected].description}
          </p>
          {projects[selected].github ? (
            <div className="py-6">
              <PageButton title="Learn More" link={projects[selected].github} />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
