import React from "react";

export default function SocialLinks() {
  return (
    <div className="static left-0 bottom-0 px-10 pt-3">
      <div className="flex flex-col items-center">
        <div className="flex flex-row gap-6 text-white text-2xl">
          <a href="https://www.linkedin.com/in/parikshit-sood/" target="_blank" rel="noreferrer">
            <i className="ri-linkedin-box-fill text-white opacity-70 hover:opacity-100"></i>
          </a>
          <a href="https://github.com/parky-sood" target="_blank" rel="noreferrer">
            <i className="ri-github-fill text-white opacity-70 hover:opacity-100"></i>
          </a>
        </div>
      </div>
    </div>
  );
}
